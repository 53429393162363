import { usePlayerStore } from '~/features/techies/store/player.store';
import { useAlertStore } from '~/store/alert/alert.store';

import { EInfoTypes, type IInfoGamePlayingCoords, type TInfoGame } from '~/features/techies/types/info/client.types';
import type { TPossibleNull } from '~/types/Shared.types';
import type { IError } from '~/repository/extensions/error/error.types';

import TechiesRepository from '~/features/techies/api/repository';
import { EStepStates } from '~/features/techies/types/step/client.types';
import { getBombsCoords } from '~/features/techies/utils/bomb.utils';
import { useUserStore } from '~/store/user/user.store';

import { BLOWN_TIME, SHUFFLE_TIME, STAGES } from '~/features/techies/constants/rules.constants';

import { TechiesEvents } from '~/repository/amplitude/events/techies';

export const useInfoStore = defineStore('techies/info', () => {
  const {
    $i18n: { t },
  } = useNuxtApp();

  const { play: playWinSound } = useProjectSounds(SOUND_KEYS.TECHIES.WIN);
  const { play: playLoseSound } = useProjectSounds(SOUND_KEYS.TECHIES.LOSE);

  const userStore = useUserStore();
  const alertStore = useAlertStore();

  const playerStore = usePlayerStore();
  const { coords, bombs, history } = storeToRefs(playerStore);

  const gamePrice = usePersistedRef('techies-game-price', 0);
  const game = ref<TPossibleNull<TInfoGame>>(null);

  const startStage = ref(1);
  const isLost = ref(false);
  const isWon = ref(false);
  const isShuffling = ref(false);

  const isStepLoading = ref(false);
  const isStartLoading = ref(false);

  const shuffleTimer = ref<TPossibleNull<ReturnType<typeof setTimeout>>>(null);
  const shuffleColumn = ref(0);

  const isGameStarted = computed(() => game.value?.type === EInfoTypes.PLAYING);

  const getStartStage = (price: number) => {
    const stages = {
      20: 1,
      40: 3,
      60: 4,
    };

    if (price in stages) return stages[price as keyof typeof stages];
    return 1;
  };

  const getInfo = async () => {
    try {
      const response = await TechiesRepository.getInfo();
      game.value = response;

      startStage.value = getStartStage(gamePrice.value);

      if (game.value.type === EInfoTypes.PLAYING && game.value.coords.length) {
        const lastCoord = game.value.coords.pop()!;
        coords.value = { x: lastCoord.x - 1, y: lastCoord.y };

        if (!game.value.coords.length) {
          bombs.value.push(...getBombsCoords(coords.value.x, coords.value.y));
          return;
        }

        for (const coord of game.value.coords) {
          history.value.push({ x: coord.x - 1, y: coord.y });
        }

        startStage.value = game.value.coords[0].x - 1;
      }
    } catch (error) {
      alertStore.showError({
        message: (error as IError).msg,
        title: t('techies.error.download'),
      });
    }
  };

  const startGame = async (price: number) => {
    if (isStartLoading.value) return;
    isStartLoading.value = true;

    if (shuffleTimer.value) {
      clearShuffle();
    }

    TechiesEvents.gameStarted({ 'game price': price, 'starting level': startStage.value });

    try {
      await TechiesRepository.postPaidStart(price);
      userStore.getMe();

      gamePrice.value = price;

      history.value = [];
      bombs.value = [];

      game.value = {
        coords: [],
        type: EInfoTypes.PLAYING,
      };
      startStage.value = getStartStage(price);
    } catch (error) {
      alertStore.showError({
        message: error as string,
        title: t('techies.error.start'),
      });
    } finally {
      isStartLoading.value = false;
    }
  };

  const makeStep = async (newCoords: IInfoGamePlayingCoords) => {
    if (isStepLoading.value) return;
    isStepLoading.value = true;

    try {
      const response = await TechiesRepository.postStep(newCoords);

      if (response.state === EStepStates.LOST) {
        gamePrice.value = 0;

        if (game.value?.type === EInfoTypes.PLAYING) {
          TechiesEvents.gameCompleted({ 'ending level': coords.value.x + 1 });
        }

        await getInfo();
        coords.value = {
          x: startStage.value - 1,
          y: 1,
        };

        isLost.value = true;
        playLoseSound();

        setTimeout(() => {
          isLost.value = false;
        }, BLOWN_TIME);
        return;
      }

      history.value.push(coords.value);
      coords.value = newCoords;

      if (response.state === EStepStates.NEXT) return;

      if (response.state === EStepStates.WON) {
        gamePrice.value = 0;

        if (game.value?.type === EInfoTypes.PLAYING) {
          TechiesEvents.gameCompleted({ 'ending level': coords.value.x + 1 });
        }

        history.value.push(coords.value);
        isWon.value = true;
        playWinSound();

        setTimeout(async () => {
          await navigateTo(response.caseLink, { external: true });
        }, BLOWN_TIME);
      }
    } catch (error) {
      alertStore.showError({
        message: error as string,
        title: t('techies.error.enter'),
      });
    } finally {
      isStepLoading.value = false;
    }
  };

  const clearShuffle = () => {
    if (!shuffleTimer.value) return;

    clearInterval(shuffleTimer.value);
    isShuffling.value = false;
    shuffleTimer.value = null;
    shuffleColumn.value = 0;
  };

  const shuffle = () => {
    isShuffling.value = true;

    shuffleTimer.value = setInterval(() => {
      if (shuffleColumn.value > STAGES + 1) {
        clearShuffle();
        return;
      }

      shuffleColumn.value++;
    }, SHUFFLE_TIME);
  };

  watch(startStage, (newStartStage) => {
    coords.value = {
      x: newStartStage - 1,
      y: 1,
    };
  });

  return {
    gamePrice,
    getInfo,
    isGameStarted,
    isLost,
    isShuffling,
    isStartLoading,
    isStepLoading,
    isWon,
    makeStep,
    shuffle,
    shuffleColumn,
    startGame,
    startStage,
  };
});
